// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-settings-js": () => import("./../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-templates-category-tech-js": () => import("./../src/templates/category-tech.js" /* webpackChunkName: "component---src-templates-category-tech-js" */),
  "component---src-templates-demo-entry-js": () => import("./../src/templates/demo-entry.js" /* webpackChunkName: "component---src-templates-demo-entry-js" */),
  "component---src-templates-tagged-tech-js": () => import("./../src/templates/tagged-tech.js" /* webpackChunkName: "component---src-templates-tagged-tech-js" */)
}

